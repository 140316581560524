import React, { useRef, useMemo, useEffect, useState } from "react";
import { GoogleMap, LoadScript, MarkerF, Circle, Polygon, InfoWindow } from '@react-google-maps/api';
import { Link, useHistory, useLocation } from "react-router-dom";
import companiesContext from '../context/companiesContext';
import backarrow from "../assets/left.png"
import logo from "../assets/logo1.png";
import userlocation from "../assets/currentlocation.png"
import locator from "../assets/userlocation2.png"
import approved from "../assets/approvelocation.png"

import house from "../assets/housing.webp"
import { CTA, Brand, Navbar, Slider } from "../components";
import Loader from "../components/Loader";
import {
    Footer,
    Blog,
    Possibility,
    Features,
    WhatGPT3,
    Header,
    Services,
} from "../containers";

import UserContext from '../context/userContext';
import "./css/dashboard.css"




const DashboardPScreen = () => {

    const [map, setMap] = useState();
    const [position, setPosition] = useState({ lat: null, lng: null });
    const [currentPosition, setCurrentPosition] = useState({ lat: null, lng: null });
    const [isApiLoaded, setIsApiLoaded] = useState(false); // State to track when the API is loaded
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [hoveredCoordinate, setHoveredCoordinate] = useState(null);
    const [hoverData, setHoverData] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [uncompliantAssets, setUncompliantAssets] = useState(null);
    const [compliantAsset, setCompliantAsset] = useState(null);
    const [circleAssett, setCircleAsset] = useState(null);
    const location = useLocation();
    const pathId = location.pathname.split("/")[2]
    const [categoryId, setCategoryId] = useState(null);
    const [documentlink, setDocument] = useState(null);
    // State to store the ID of the selected row
    const [selectedAssetID, setSelectedAssetID] = useState(null);
    const [action, setAction] = useState(null);
    //Display Main Dashboard
    const [dashboard, setDashboard] = useState(null);
    const [assetCategory, setAssetCategory] = useState(null);
    //Show Add Event Form
    const [addEvent, setAddEvent] = useState(null);
    const [showEvents, setShowEvent] = useState(null);
    const [optionSelected, setOptionSelected] = useState(false)
    //show map state so that only show map when it's needed
    const [showMap, setShowMap] = useState(true)
    const [records, setRecords] = useState(false);

    //CONTEXT
    const { logout, user } = UserContext();
    const { getAssets, getEvents, assets, role, loading, events, selectedCompanyID, createEvent } = companiesContext();




    // COMPONENTS
    // SideBar
    const SideBar = ({ user }) => {
        const Service = (action) => {
            if (action == "action") {
                setDashboard(false)
                setRecords(false)
                setAddEvent(false)
                setAction(true)
                setShowMap(true)

            } else if (action == "dashboard") {
                setAction(false)
                setRecords(false)
                setAddEvent(false)
                setDashboard(true)
                setShowMap(true)

            } else if (action == "records") {
                setRecords(true)
                setShowMap(false)
            }
        }
        return (
            <>
                {user ? (
                    <div class='madeby' style={{ fontSize: "30px" }}><a href='#' style={{ color: "#D8A25E" }}>{user.name}</a></div>
                ) : null}
                <div class='dashsidebar'>
                    {user && role == "Director" || role == "Manager" || role == "SuperAdmin" ? (
                        <>
                            <div onClick={() => Service("dashboard")} class='navigation-elm'><span class='nav-text'><i class="fa fa-fw fa-area-chart"></i><span class='text-short'>Assets</span> <span class='text-long'>Dashboard</span></span>
                            </div>
                            {/* <div class='sub-navigation-elm'>Asset Performance</div>
                        <div class='sub-navigation-elm'>Asset Actions</div>
                        <div class='sub-navigation-elm'>Users</div> */}
                        </>
                    ) : null}
                    {user && role == "Director" || role == "Manager" || role == "RecordsOfficer" || role == "SuperAdmin" ? (
                        <>
                            <div onClick={() => Service('records')} class='navigation-elm'><span class='nav-text'><i class="fa fa-solid fa-folder-open"></i><span class='text-short' style={{ marginLeft: "12px" }}>Asset</span> <span class='text-long'>Repository</span></span> </div>
                        </>
                    ) : null}

                    {user && role == "Manager" || role == "SuperAdmin" ? (
                        <>
                            <div onClick={() => Service("action")} class='navigation-elm'><span class='nav-text'><i class="fa  fa-solid fa-coins"></i><span class='text-short' style={{ marginLeft: "12px" }}>Asset</span> <span class='text-long'>Milestones</span></span> </div>
                        </>
                    ) : null}

                    <div class='navigation-elm'><Link to="/profile"><span class='nav-text'><i class="fa fa-solid fa-user"></i><span class='text-short' style={{ marginLeft: "12px" }}>Companies</span> <span class='text-long'>Repository</span></span></Link>
                    </div>

                    {user && role == "Director" || role == "SuperAdmin" ? (
                        <>
                            <div class='navigation-elm'><span class='nav-text'><i class="fa fa-solid fa-chart-simple"></i><span class='text-short' style={{ marginLeft: "12px" }}>Assets</span> <span class='text-long'>Stastics</span>
                                <div class='update-number'>3</div>
                            </span>
                            </div>
                        </>) : null}

                        {user && role == "Director" || role == "SuperAdmin" ? (
                        <>
                            <div class='navigation-elm'><span class='nav-text'><i class="fa fa-solid fa-chart-simple"></i><span class='text-short' style={{ marginLeft: "12px" }}>Queue</span> <span class='text-long'>Trees</span>
                                <div class='update-number'>3</div>
                            </span>
                            </div>
                        </>) : null}

                    {user && role == "Manager" || role == "Director" || role == "SuperAdmin" ? (
                        <>
                            <div class='navigation-elm'><span class='nav-text'><i class="fa fa-fw fa-gear"></i><span class='text-short'>Settings</span></span>
                            </div>
                        </>
                    ) : null}

                </div>
            </>
        )
    }

    // Metrics
    const Metrics = ({ revenue, unitsSold }) => (
        <>
            <div className='dashrow'>
                <div className='key-metrics white-border'>
                    <div className='small'>Revenue</div>
                    {revenue}$
                </div>
                <div className='key-metrics blue-border'>
                    <div className='small'>Units Sold</div>{unitsSold}
                </div>
            </div>
        </>
    );

    //Statistics
    const Statics = () => {
        return (
            <div class='dashrow'>
                <div class='piechart-element'>
                    <div class='piechart-label'>
                        <div class='label-heading'>Asset</div>
                        <p class='small'>Compliance <span class='main-color'>Completion</span>.Rate. </p>
                    </div>
                    <div class='piechart-percent main-color'>68%</div>
                    <div class='piechart-container'>

                        <canvas id="piechart"></canvas>
                    </div>
                </div>
                <div class='piechart-element'>

                    <div class='piechart-label'>
                        <div class='label-heading'>Revenue</div>
                        <p class='small'>Realized  <span class='main-color'> 360 </span> Assets</p>
                    </div>
                    <div class='piechart-percent main-color'>$100</div>
                    <div class='piechart-container'>
                        <canvas id="piechart2"></canvas>
                    </div>

                </div>
                <div class='piechart-element'>
                    <div class='piechart-label'>
                        <div class='label-heading'>Revenue</div>
                        <p class='small'>Expected
                            <span class='main-color'>2000</span> Assets .
                        </p>
                    </div>
                    <div class='piechart-percent main-color'>$10000</div>
                    <div class='piechart-container'>
                        <canvas id="piechart3"></canvas>
                    </div>

                </div>
            </div>
        )
    }
    //Charts
    const Charts = () => {
        return (
            <div class='linechart-element'><canvas id="linechart"> </canvas>
            </div>
        )
    }
    //END COMPONENTS
    const searchRef = useRef()
    const orderContainerRef = useRef(null);


    //Map Style Properties
    const containerStyle = {
        width: '100%',
        height: '340px',
        marginRight: "auto",
        marginLeft: "auto",
        borderBottom: "6px double #F8FAFC",
        borderTop: "6px double #F8FAFC",
        borderRight: "6px double #F8FAFC",
        borderLeft: "6px double #F8FAFC",
        // cursor: plotting ? 'crosshair' : 'auto', // Set cursor style based on plottingMode
    };


    // Circle Style Properties
    const circleOptions = {
        strokeColor: "#F4F6FF",
        strokeOpacity: 0.9,
        strokeWeight: 2,
        fillColor: "linear-gradient( 97.3deg,  rgba(25,50,70,0.81) 10.7%, rgba(250, 81, 9, 0.72) 39.5%, rgba(255,192,0,0.81) 69.7% )",
        fillOpacity: 0.4,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 3, // Radius in meters
        zIndex: 1,
    };

    // Scroll up by a certain amount (e.g., 100 pixels)
    //For Events on the right side of the dashboard
    const scrollUp = () => {
        if (orderContainerRef.current) {
            orderContainerRef.current.scrollBy({
                top: -100, // Negative value scrolls up
                behavior: "smooth",
            });
        }
    };

    // Scroll down by a certain amount (e.g., 100 pixels)
    //For Events on the right side of the dashboard
    const scrollDown = () => {
        if (orderContainerRef.current) {
            orderContainerRef.current.scrollBy({
                top: 100, // Positive value scrolls down
                behavior: "smooth",
            });
        }
    };

    //Information Window on selected markers on the map
    const handleInfoWindowClose = () => {
        setShowInfoWindow(false);
    };

    //When you click a marker
    const handleMarkerHover = (id) => {
        for (const asset of selectedAsset.assets) {
            if (asset._id == id) {
                setHoveredCoordinate(asset);
                setPosition({ lat: asset.centerCoordinates.lat, lng: asset.centerCoordinates.lng })
                setDocument(`https://drive.google.com/file/d/${getDriveFileId(asset.assetDocuments[0].documentDownloadLink)}/view`)
            }
        }
        setShowInfoWindow(true);
    };

    //Category Selection belonging to a selected company
    const categorySelect = (id) => {
        for (const asset of assets) {
            if (asset._id == id) {
                setCompliantAsset(null)
                setAssetCategory(asset.category)
                setSelectedAsset(asset);
                setUncompliantAssets(asset);
            }
        }

        //Get Events here
        if (selectedCompanyID !== null && id !== null) {
            getEvents(id, selectedCompanyID);
        }

    }

    //For searching for assets by name or phone number
    const searchAsset = () => {
        const searchAsset = searchRef.current.value;
        console.log('Search String :' + searchAsset);

        // If the search input is empty, return without filtering
        if (searchAsset === "") {
            return;
        }

        // Filter assets by operatorPhone or operatorName
        const filteredAssets = selectedAsset.assets.filter(asset =>
            asset.operatorPhone.includes(searchAsset) ||
            asset.operatorName.toLowerCase().includes(searchAsset.toLowerCase())
        );

        // Clear the search input
        searchRef.current.value = "";

        console.log("Searched Assets: " + JSON.stringify(filteredAssets));

        // Update the state with the filtered assets
        setSelectedAsset({ assets: filteredAssets });
    };

    //Move directly to the circled coordinates
    const circleAsset = (id) => {
        for (const asset of selectedAsset.assets) {
            if (asset._id == id) {
                setCircleAsset(asset);
                setPosition({ lat: asset.centerCoordinates.lat, lng: asset.centerCoordinates.lng })
            }
        }
    }

    //For getting a url to use later for viewing on the browser
    function getDriveFileId(url) {
        const urlParams = new URLSearchParams(url.split('?')[1]); // Extract query string
        const fileId = urlParams.get('id'); // Get the 'id' parameter
        return fileId;
    }
    // Function to handle row click
    const handleRowClick = (assetID) => {
        setSelectedAssetID(assetID); // Update the selected asset ID
        circleAsset(assetID); // Call the circleAsset function with the asset ID
    };

    // Function Get Compliant assets
    const EventAssets = (eventId) => {
        const filteredAssets = events.filter(event => event._id === eventId);
        if (filteredAssets.length > 0) {
            // Third array to store matching objects from array1
            const matchingAssets = [];

            // Filter selectedAsset.assets and simultaneously store matching objects
            const remainingAssets = uncompliantAssets.assets.filter(asset => {
                const matchedFilteredAsset = filteredAssets[0].performedOn.find(filteredAsset => asset._id === filteredAsset.assetID);

                if (matchedFilteredAsset) {
                    // Create a copy of the asset object and add fields from matchedFilteredAsset
                    const updatedAsset = {
                        ...asset,
                        valueCollected: matchedFilteredAsset.valueCollected,
                        doneOn: matchedFilteredAsset.implementationDate
                    };  // Add the new fields to the asset

                    matchingAssets.push(updatedAsset);  // Push updated asset to matchingAssets
                }

                return !matchedFilteredAsset;  // Exclude matching assets from remainingAssets
            });

            // Update the state for compliantAsset and uncompliantAssets
            setCompliantAsset(matchingAssets);
            setAddEvent(false)
            setShowEvent(true)
            setUncompliantAssets(prev => ({ ...prev, assets: remainingAssets }));
        }
    };

    //For Add Event Form select input
    const [selectedCategoryValue, setSelectedCategoryValue] = useState("");

    // Event handler for updating the selected value
    const handleSelectCategoryChange = (event) => {
        setSelectedCategoryValue(event.target.value); // Get the value from the select element
        console.log("CATEGORY SELECT :" + selectedCategoryValue)
    }; // End of handleSelectCategoryChange

    const [selecteFinancial, setSelectedFinancial] = useState("")
    // Event handler for updating the selected value
    const handleSelectFinacialChange = (event) => {
        setSelectedFinancial(event.target.value); // Get the value from the select element
        console.log("FINANCIAL SELECT :" + selecteFinancial)
    }; // End of handleSelectCategoryChange

    const [eventLoop, setEventLoop] = useState("")
    // Event handler for updating the selected value
    const handleEventLoopChange = (event) => {
        setEventLoop(event.target.value); // Get the value from the select element
        console.log("Event Loop :" + eventLoop)
    }; // End of handleSelectCategoryChange

    // Local state for Add Event
    const eventNameRef = useRef()
    const descriptionRef = useRef()
    const amountRef = useRef()
    const installmentRef = useRef()

    const createE = () => {
        console.log("Category ID : " + selectedCategoryValue + "Category ID : " + selecteFinancial)

        if (selectedCategoryValue !== '' || selecteFinancial !== '' || eventLoop !=='') {
            const eventName = eventNameRef.current.value
            const description = descriptionRef.current.value
            const amount = amountRef.current.value
            let installment = "";
            if(!eventLoop){
                return
            }
            if (eventName !== '' || description !== '' || amount !== '') {
                if(eventLoop && eventLoop !== "N/A"){
                    installment = installmentRef.current.value
                }
                createEvent(selectedCompanyID, eventName, description, selectedCategoryValue, amount, selecteFinancial,eventLoop, installment)
                if (!loading) {
                    setAddEvent(false)
                    setShowEvent(true)
                    setShowMap(true)
                }
            }
        }
    }


    //End Add Event Form select input

    const AddEvent = () => {
        setShowEvent(false)
        setShowMap(false)
        setAddEvent(true)
    }

    const showEvent = () => {
        setDashboard(false)
        setAddEvent(false)
        setAction(true)
        setShowMap(true)
    }

    useEffect(() => {
        // console.log("Id for company :"+id)
        getAssets(pathId);
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                setPosition({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });

                setCurrentPosition({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })
            });
        } else {
            console.log("Geolocation is not available in your browser.");
        }

        if (role == "SuperAdmin") {
            setDashboard(true)
        }
    }, []);


    // Animation for the marker; only defined if google.maps is loaded
    const bounceAnimation = useMemo(() => {
        return isApiLoaded && window.google && window.google.maps ? window.google.maps.Animation.BOUNCE : null;
    }, [isApiLoaded]);

    const dropAnimation = useMemo(() => {
        return isApiLoaded && window.google && window.google.maps ? window.google.maps.Animation.DROP : null;
    }, [isApiLoaded]);

    return (
        <>
            <div class='dashcontainer'>
                <SideBar user={user} />
                {showMap && (
                    <>
                        <Metrics revenue={30} unitsSold={20} />
                        <Charts />
                        {/* <Statics /> */}
                    </>)}

                {user && role == "Director" || role == "Manager" || role == "SuperAdmin" ? (
                    <div className='dashrow'>
                        <div className='stats-element'>
                            <div className='element-header'>ASSET MAP</div>
                            {action && !records && (
                                <div className='dashtable-header' style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <div className='element-header' style={{ background: "linear-gradient(to top, #dfe9f3 0%, white 100%)", color: "black" }}>EVENT OPTIONS</div>
                                    <div className={optionSelected ? `country-selected` : `country-select`} onClick={AddEvent}>Add Event</div>
                                    <div className='country-select' >Stop Event</div>
                                    <div className='country-select' onClick={showEvent}>Show Events</div>
                                </div>
                            )}

                            {records && (
                                <div className='dashtable-header' style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <div className='element-header' style={{ background: "linear-gradient(to top, #dfe9f3 0%, white 100%)", color: "black" }}>REPOSITORY OPTIONS</div>
                                    <div className={optionSelected ? `country-selected` : `country-select`} onClick={AddEvent}>Event Compliance</div>
                                    <div className='country-select' >Stop Event</div>
                                    <div className='country-select' onClick={showEvent}>Show Events</div>
                                </div>
                            )}
                            <div class='stats-container' id='stats-element'>
                                {loading && (<Loader />)}
                                <div class='map-container'>
                                    {position.lat && position.lng ? (
                                        <div>
                                            {records && (
                                                <form style={{ marginBottom: "20px" }}>
                                                    <input type="text" className="dashSearchInput" placeholder="Event name" ref={eventNameRef} />
                                                    <input type="text" className="dashSearchInput" placeholder="Describe event" ref={descriptionRef} />
                                                    <label for="asset-category" style={{ marginLeft: "10px" }}>Choose Asset Category:</label> <br />

                                                    <select name="AssetCategory" value={selectedCategoryValue} className="dashSearchInput" onChange={handleSelectCategoryChange}>
                                                        <option value="">--Asset Category--</option>
                                                        {events && (
                                                            <>
                                                            {events.map((event, index) => (
                                                                <option value={event._id} key={event._id} >{event.eventName}</option>
                                                            ))}
                                                            </>
                                                        ) }
                                                        
                                                    </select>

                                                    <select name="financial" value={selecteFinancial} className="dashSearchInput" onChange={handleSelectFinacialChange}>
                                                        <option value="">--Financial Activity--</option>
                                                        <option value="Expanse" >Expanse</option>
                                                        <option value="Income" >Income</option>
                                                        <option value="Veryfication" >Veryfication</option>
                                                    </select>
                                                    <input type="number" className="dashSearchInput" placeholder="Amount" ref={amountRef} />

                                                    <button type="submit" style={{ width: "60%", marginLeft: "10px", backgroundColor: "orange" }} onClick={createE}>Create Event</button>
                                                </form>
                                            )}

                                            {addEvent && (
                                                <form style={{ marginBottom: "20px" }}>
                                                    <input type="text" className="dashSearchInput" placeholder="Event name" ref={eventNameRef} required/>
                                                    <input type="text" className="dashSearchInput" placeholder="Describe event" ref={descriptionRef} required/>
                                                    <label for="asset-category" style={{ marginLeft: "10px" }}>Choose Asset Category:</label> <br />

                                                    <select name="AssetCategory" value={selectedCategoryValue} className="dashSearchInput" onChange={handleSelectCategoryChange} required>
                                                        <option value="">--Asset Category--</option>
                                                        {assets.map((asset, index) => (
                                                            <option value={asset._id} key={asset._id} >{asset.category}</option>
                                                        ))}
                                                    </select>

                                                    <select name="financial" value={selecteFinancial} className="dashSearchInput" onChange={handleSelectFinacialChange} required>
                                                        <option value="">--Financial Activity--</option>
                                                        <option value="Expanse" >Expanse</option>
                                                        <option value="Income" >Income</option>
                                                        <option value="Veryfication" >Veryfication</option>
                                                    </select>
                                                    <select name="frequency" value={eventLoop} className="dashSearchInput" onChange={handleEventLoopChange} required>
                                                        <option value="">--Event Loop--</option>
                                                        <option value="Monthly" >Monthly</option>
                                                        <option value="Yearly" >Yearly</option>
                                                        <option value="N/A" >Not Applicable</option>
                                                    </select>

                                                    <input type="number" className="dashSearchInput" placeholder="Total Asset Amount" ref={amountRef} required/>
                                                    {eventLoop && eventLoop !=="N/A" && <input type="number" className="dashSearchInput" placeholder="Instalment Amount" ref={installmentRef} />}
                                                    <button type="submit" style={{ width: "60%", marginLeft: "10px", backgroundColor: "orange" }} onClick={createE}>Create Event</button>
                                                </form>
                                            )}

                                            <LoadScript
                                                googleMapsApiKey='AIzaSyCMSkqh_rKJthabvItAZKNenF1ksHZbGfE'
                                                mapIds={['ba39c0a5e6fb900e']}
                                                onLoad={() => setIsApiLoaded(true)} // Set API loaded state to true when ready
                                            >

                                                {showMap && (
                                                    <GoogleMap
                                                        mapContainerStyle={containerStyle}
                                                        center={{ lat: position.lat, lng: position.lng }}
                                                        options={{
                                                            mapId: 'ba39c0a5e6fb900e',
                                                            mapTypeId: 'satellite' // Set map type to satellite
                                                        }}
                                                        zoom={18}
                                                        onLoad={(map) => setMap(map)}
                                                    >

                                                        {/* Current Location Marker */}
                                                        {currentPosition.lat && currentPosition.lng && bounceAnimation && (  // Ensure marker displays if position is set
                                                            <>
                                                                <MarkerF
                                                                    position={{ lat: currentPosition.lat, lng: currentPosition.lng }}
                                                                    icon={{
                                                                        url: userlocation,
                                                                        scaledSize: {
                                                                            width: 50,
                                                                            height: 50,
                                                                        },
                                                                    }}
                                                                    // label={{text:`You`,color:'#FFBE98', fontSize: "13px", fontWeight: "bold"}}
                                                                    animation={bounceAnimation}
                                                                />
                                                            </>
                                                        )}


                                                        {dashboard && selectedAsset && dropAnimation && selectedAsset.assets.map((asset) => (
                                                            <MarkerF
                                                                key={asset._id}
                                                                position={{ lat: asset.centerCoordinates.lat, lng: asset.centerCoordinates.lng }}
                                                                icon={{
                                                                    url: locator,
                                                                    scaledSize: {
                                                                        width: 50,
                                                                        height: 50,
                                                                    },
                                                                }}
                                                                animation={bounceAnimation}
                                                                onClick={() => handleMarkerHover(asset._id)}
                                                            />
                                                        ))}

                                                        {action && uncompliantAssets && dropAnimation && uncompliantAssets.assets.map((asset) => (
                                                            <MarkerF
                                                                key={asset._id}
                                                                position={{ lat: asset.centerCoordinates.lat, lng: asset.centerCoordinates.lng }}
                                                                icon={{
                                                                    url: locator,
                                                                    scaledSize: {
                                                                        width: 50,
                                                                        height: 50,
                                                                    },
                                                                }}
                                                                animation={bounceAnimation}
                                                                onClick={() => handleMarkerHover(asset._id)}
                                                            />
                                                        ))}

                                                        {action && compliantAsset && dropAnimation && compliantAsset.map((asset) => (
                                                            <MarkerF
                                                                key={asset._id}
                                                                position={{ lat: asset.centerCoordinates.lat, lng: asset.centerCoordinates.lng }}
                                                                icon={{
                                                                    url: approved,
                                                                    scaledSize: {
                                                                        width: 50,
                                                                        height: 50,
                                                                    },
                                                                }}
                                                                animation={bounceAnimation}
                                                                onClick={() => handleMarkerHover(asset._id)}
                                                            />
                                                        ))}

                                                        {circleAssett && (
                                                            <Circle
                                                                center={{ lat: circleAssett.centerCoordinates.lat, lng: circleAssett.centerCoordinates.lng }} // Another location
                                                                options={{ ...circleOptions }}
                                                            />
                                                        )}

                                                        {showInfoWindow && (
                                                            <InfoWindow
                                                                position={hoveredCoordinate.centerCoordinates}
                                                                onCloseClick={handleInfoWindowClose}
                                                            >
                                                                <div style={{ background: "linear-gradient( 109.6deg,  rgba(0,0,0,0.93) 11.2%, rgba(63,61,61,1) 78.9% )", height: "350px", width: "350px" }}>
                                                                    <h1 style={{ fontSize: '20px', color: "#FFF1DB", textAlign: "left", padding: "10px" }}>{hoveredCoordinate.operatorName}</h1>
                                                                    <p style={{ fontSize: "15px", color: "white", padding: "10px" }}>ID: {hoveredCoordinate.operatorID}</p>
                                                                    <p style={{ fontSize: "15px", color: "white", padding: "10px" }}>Phone: {hoveredCoordinate.operatorPhone}</p>
                                                                    <p style={{ fontSize: "15px", color: "white", padding: "10px" }}>Certificate Number: {hoveredCoordinate.assetNumber}</p>
                                                                    {documentlink && <Link onClick={() => window.open(documentlink, "_blank")} style={{ background: "#FFA27F", padding: "15px", color: "white", marginTop: "20px", marginLeft: "10px", textDecoration: "none" }}>
                                                                        View File
                                                                    </Link>}
                                                                </div>
                                                            </InfoWindow>
                                                        )}
                                                    </GoogleMap>
                                                )}


                                            </LoadScript>
                                        </div>
                                    ) : (
                                        <Loader />
                                    )}
                                </div>





                                {assets && !addEvent ? (
                                    <>
                                        <div class='dashtable-header' style={{ marginTop: "10px", marginBottom: "10px" }}>
                                            <div class='element-header' style={{ background: "linear-gradient(to top, #dfe9f3 0%, white 100%)", color: "black" }}>ASSET CATEGORIES</div>
                                            {assets.map((asset, index) => (
                                                <div className='country-select' key={asset._id} onClick={() => categorySelect(asset._id)}>{asset.category}</div>
                                            ))}
                                        </div>

                                    </>
                                ) :
                                    null
                                }




                                {!compliantAsset && !addEvent && (
                                    <>
                                        <div style={{ marginTop: "35px", display: "flex" }}>
                                            <input type="text" name="assetSearch" placeholder="Search Asset" required="required" className="dashSearchInput" ref={searchRef} /> <button style={{ backgroundColor: "#FF8225", width: "80px", height: "44px", marginLeft: "5px" }} onClick={searchAsset}>Search</button>
                                        </div>
                                        <ul className="tableHead" style={{color: "black"}} >
                                            <li>Operator Name</li>
                                            <li>Operator ID</li>
                                            <li>Operator Phone</li>
                                            <li>Asset Name</li>
                                            <li>Asset Number</li>
                                            <li>Latitude</li>
                                            <li>Longitude</li>
                                        </ul>
                                        <div class='stats-table' style={{ zIndex: "100" }}>
                                            <table class='performance-table' style={{ zIndex: "100" }}>
                                                <tbody className="table-body">
                                                    {selectedAsset ? (
                                                        <>
                                                            {selectedAsset.assets.map((asset, index) => (
                                                                <tr key={asset._id}
                                                                    className={`assetRow ${selectedAssetID === asset._id ? 'selected' : ''}`}
                                                                    onClick={() => handleRowClick(asset._id)} >
                                                                    <td class='table-desc'>{asset.operatorName}</td>
                                                                    <td>{asset.operatorID}</td>
                                                                    <td>{asset.operatorPhone}</td>
                                                                    <td>{asset.assetName}</td>
                                                                    <td>{asset.assetNumber}</td>
                                                                    <td>{asset.centerCoordinates.lat}</td>
                                                                    <td>{asset.centerCoordinates.lng}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ) : null}

                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                )}

                                {action && showEvents && compliantAsset && (
                                    <>
                                        <ul className="tableHead" style={{ background: "linear-gradient( 109.6deg,  rgba(0,0,0,0.93) 11.2%, rgba(63,61,61,1) 78.9% )" }}>
                                            <li>Operator Name</li>
                                            <li>Operator ID</li>
                                            <li>Operator Phone</li>
                                            <li>Asset Name</li>
                                            <li>Asset Number</li>
                                            <li>Amount</li>
                                            <li>Date</li>
                                        </ul>
                                        <div class='stats-table' style={{ zIndex: "100" }}>
                                            <table class='performance-table' style={{ zIndex: "100" }}>
                                                <tbody className="table-body">
                                                    {compliantAsset && compliantAsset.map((asset, index) => (
                                                        <tr key={asset._id}
                                                            className={`assetRow ${selectedAssetID === asset._id ? 'selected' : ''}`}
                                                            style={{ backgroundColor: "#72BF78" }}
                                                            onClick={() => handleRowClick(asset._id)} >
                                                            <td class='table-desc'>{asset.operatorName}</td>
                                                            <td>{asset.operatorID}</td>
                                                            <td>{asset.operatorPhone}</td>
                                                            <td>{asset.assetName}</td>
                                                            <td>{asset.assetNumber}</td>
                                                            <td>{asset.valueCollected}</td>
                                                            <td>{asset.doneOn}</td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>

                                    </>
                                )}
                            </div>

                        </div>

                        {showMap && events && action ? (
                            <div className='order-element'>
                                <div class='element-header' style={{ background: "linear-gradient(to top, #dfe9f3 0%, white 100%)", color: "black" }}>{assetCategory} EVENTS</div>
                                <div class='order-scroll up' id='order-scrollup' onClick={scrollUp}><i class="fa fa-chevron-up"></i></div>
                                <div class='order-container' id='order-container' ref={orderContainerRef}>
                                    {events.map((event, index) => (
                                        <div className='order-item' id='order-item' key={event._id} onClick={() => EventAssets(event._id)}>
                                            <h1 style={{ textAlign: "Left", color: "black" }}>{event.eventName}</h1>
                                            <p style={{color: "black"}}>Status: <span class='main-color'>{event.status}</span><br />{event.receipts} : Amount per asset ${event.assetValue}</p>
                                            <p style={{color: "black"}}>Creation Date: {event.createdAt}<br /> Description: {event.description}</p>
                                        </div>
                                    ))}

                                    {/* <div class='order-item'>
                                     <h1>Order #313-55416218</h1>
                                     <p>Status: <span class='main-color'>Dispatched</span><br />Tracking Number: 1431 2349 11</p>
                                     <p>Order Date: 12/24/16<br /> Customer Contact: random.guy@gmail.com</p>
                                 </div>
                                 <div class='order-item highlight'>
                                     <h1>Order #535-71122781</h1>
                                     <p>Status: <span class='highlight'>Awaiting Shipment</span><br />Tracking Number: -</p>
                                     <p>Order Date: 12/24/16<br /> Customer Contact: aname.store@aol.com</p>
                                 </div>
                                 <div class='order-item highlight'>
                                     <h1>Order #455-51133742</h1>
                                     <p>Status: <span class='highlight'>Awaiting Shipment</span><br />Tracking Number: -</p>
                                     <p>Order Date: 12/24/16<br /> Customer Contact: special.order@yahoo.com</p>
                                 </div>
                                 <div class='order-item hold'>
                                     <h1>Order #734-45422551</h1>
                                     <p>Status: <span class='inactive'>On Hold</span><br />Tracking Number: -</p>
                                     <p>Order Date: 12/20/16<br /> Customer Contact: nobody123@gmail.com</p>
                                 </div>
                                 <div class='order-item hold'>
                                     <h1>Order #532-13422331</h1>
                                     <p>Status: <span class='inactive'>On Hold</span><br />Tracking Number: -</p>
                                     <p>Order Date: 12/19/16<br /> Customer Contact: random333.star@gmail.com</p>
                                 </div>
                                 <div class='order-item hold'>
                                     <h1>Order #922-25491351</h1>
                                     <p>Status: <span class='inactive'>On Hold</span><br />Tracking Number: -</p>
                                     <p>Order Date: 12/19/16<br /> Customer Contact: santa@gmail.com</p>
                                 </div> */}
                                </div>
                                <div class='order-scroll' id='order-scrolldown' onClick={scrollDown}><i class="fa fa-chevron-down"></i></div>
                            </div>
                        ) : null}

                    </div>
                ) : null}

                {/* <div class='element-header social'>Workflow</div>

                <div class='dashrow todo'>
                    <div class='social-element'>
                        <div class='chatbox-container'>
                            <div class='anna-image'></div>
                            <div class='chatbox-left'><span class='username'>Ann:</span>Hi <span class='highlight'>@Jimmy</span>, please make sure to have a look at <a href='#' class='main-color'>#413-8148622</a>, needs dispatch ASAP.</div>
                        </div>
                        <div class='chatbox-container'>
                            <div class='anna-image'></div>
                            <div class='chatbox-left'><span class='username'>Ann:</span>Aw sorry, forgot to attach the updated invoice.</div>
                        </div>
                        <div class='chatbox-container'>
                            <div class='jimmy-image'></div>
                            <div class='chatbox-left'><span class='username'>Jimmy:</span>Heya <span class='highlight'>@Ann</span>, don't you worry - I'm here to save the day. Order is already dispached!</div>
                        </div>
                        <p class='spacing-adjuster'></p>
                        <div class='chat-input'>
                            <textarea rows='1' placeholder='Add a comment to this workflow...'></textarea>
                            <div class='submit-icon'><i class="fa fa-paper-plane-o"></i></div>
                        </div>
                    </div>
                    <div class='todo-element'>
                        <div class='table-header todo'>
                            <div class='country-select'>Files</div>
                            <div class='country-select'>Todos</div>
                        </div>
                        <div class='file-container small'>

                            <ul className="dashul">
                                <li className="dashli"><span><i class="fa fa-file-pdf-o"></i> invoice#413-814updated.pdf</span></li>
                                <li className="dashli"><i class="fa fa-file-excel-o"></i> revenue-summary.xls</li>
                                <li className="dashli"><i class="fa fa-file-pdf-o"></i> region30_map.pdf</li>
                            </ul>

                            <div class='draghere'><i class="fa fa-lg fa-cloud-upload"></i>Drag new files here</div>

                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
};

export default DashboardPScreen;