import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import UserContext from '../context/userContext.js'; // for managing user states
import Loader from "../components/Loader"; // Component to show while authentication is in progress

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { user, loading } = UserContext((state) => ({
    user: state.user,
    loading: state.loading,
  }));

  if (loading) {
    return <Loader />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return user ? <Component {...props} /> : <Redirect to="/signin" />;
      }}
    />
  );
};

export default ProtectedRoute;
