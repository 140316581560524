import React, { useRef, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Meta from "../components/Meta";
import {
  Footer,
  Blog,
  Possibility,
  Features,
  WhatGPT3,
  Header,
  Services,
} from "../containers";

import { CTA, Brand, Navbar, Slider } from "../components";
import Carousel from "../components/Carousel"
import MainComponent from "../components/MainComponent.js"
import Loader from "../components/Loader";
import './css/home.css';
import './css/animations.css'
import closebutton from '../assets/close_button.png';
import search from '../assets/search.png';
import digital_asset from '../assets/digital-asset.png';
import funding from '../assets/funding.png';
import gains from '../assets/gains.png';
import monitor from '../assets/monitor.png';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import africa from "../assets/handshake.mp4"

// Import Swiper styles
import 'swiper/swiper-bundle.css';

// Import Navigation and Pagination styles
// import 'swiper/modules/navigation/navigation.min.css';
// import 'swiper/modules/pagination/pagination.min.css';



const slideData = [
  {
    index: 0,
    headline: "",
    button: "Housefin",
    src: "https://cdn3d.iconscout.com/3d/premium/thumb/bank-interest-4086294-3379627.png",
    link: "/banking",
  },
  {
    index: 1,
    headline: "",
    button: "Agro Power",
    src: "https://cdn3d.iconscout.com/3d/premium/thumb/smart-farm-application-6790862-5600930.png",
    link: "/commodity/agropower",
  },
  {
    index: 2,
    headline: "",
    button: "Cleverhills",
    src: "https://cdn.pixabay.com/photo/2022/04/11/23/57/house-7126876_1280.png",
    link: "/contract",
  },
];

const featuresData = [
  {
    title: "Collateral Value",
    text: "Megajoules represent tangible portions of assets held by HANDAC, acting as collateral. This collateral value provides contributors with a tangible and tradable asset that backs their contributions",
  },
  {
    title: "Tradeability",
    text: "Megajoules are designed to be tradeable assets within the MIAMI ENGINE ecosystem. Contributors can exchange Megajoules for other assets, providing a level of liquidity and flexibility in managing their portfolio.",
  },
  {
    title: "Transparent Ownership",
    text: "Megajoules represent ownership of specific portions of mineral assets held by HANDAC. This transparency in ownership provides contributors with a clear understanding of their stake in the mineral assets, fostering trust and confidence",
  },
  {
    title: "Facilitation of Asset Exchange",
    text: "Megajoules play a role in facilitating the exchange of assets within the MIAMI ENGINE ecosystem. When contributors wish to exchange Megajoules for other assets, an escrow mechanism ensures a secure and transparent transaction.",
  },
];

const AssetRepoData = [
  {
    title: "Detailed Asset Profiles",
    text: "Every asset in the repository has a dedicated profile page capturing all essential information. This includes unique asset identifiers, specifications, purchase details, ownership history, auditing schedules, and usage details. With this depth of information, users can understand the full context of an asset at a glance, aiding in audits, compliance checks, and operational decisions.",
  },
  {
    title: "Document Storage & Compliance",
    text: "MIAMI offers secure digital storage for asset-related documents, ensuring all necessary paperwork—such as purchase agreements, licenses, warranties, and regulatory certifications—is readily available. Compliance is simplified, as users can easily verify that assets meet industry standards and local regulations, reducing legal risk and enhancing transparency for stakeholders.",
  },
  {
    title: "Ownership & Status Tracking",
    text: "Capture real-time changes in ownership, asset status updates, and performance metrics.",
  },
  {
    title: "Location Mapping",
    text: "Integrating seamlessly with mapping tools like Google Maps, MIAMI allows users to plot asset locations with precise geolocation coordinates. This is particularly beneficial for assets spread across multiple sites, such as industrial equipment, infrastructure, and real estate. Users can view and manage asset locations visually, which enhances logistics, operational planning, and strategic oversight.",
  },
];

const AssetMonitoringData = [
  {
    title: "Real-Time Status Tracking",
    text: "Monitor assets 24/7 with instant status updates. Receive alerts on critical changes in asset condition or location, helping you respond swiftly to avoid disruptions. Stay informed about every asset’s operational status, maintenance needs, and utilization to keep your operations running smoothly.",
  },
  {
    title: "Geolocation & Movement Tracking",
    text: "A visual map interface lets you track asset movement patterns, ensuring compliance with approved locations and providing data to optimize routing or logistics.",
  },
  {
    title: "Performance Insights & Analytics",
    text: "Get actionable insights into asset performance with analytics tailored to your needs. Access data on utilization rates, efficiency metrics, and performance patterns to identify underperforming assets and optimize asset deployment. With historical and predictive analytics, companies can make better decisions and forecast future needs.",
  },
  {
    title: "Data-Driven Reporting",
    text: "Generate reports based on your monitoring data, covering everything from real-time status logs to monthly performance summaries. Use these reports to assess asset utilization, maintenance frequency, and other essential metrics for more data-driven asset management and improved ROI.",
  },
];
const slides = [
  {
    image: {
      src: "https://placedog.net/600/808",
      alt: ""
    },
    url: "/",
    id: 1,
    title: "Lorem ipsum",
    text:
      "Augue eget arcu dictum varius duis. Diam in arcu cursus euismod quis viverra nibh cras pulvinar. Amet volutpat consequat mauris nunc congue nisi vitae suscipit tellus. Ac tortor vitae purus faucibus ornare."
  },
  {
    image: {
      src: "https://placedog.net/600/804",
      alt: ""
    },
    url: "/",
    id: 2,
    title: "Nunc mattis",
    text:
      "Ornare massa eget egestas purus viverra accumsan in. Cursus in hac habitasse platea dictumst quisque sagittis purus. Eget duis at tellus at urna condimentum mattis. Adipiscing elit duis tristique sollicitudin nibh sit amet commodo nulla. Est ante in nibh mauris. Vitae congue eu consequat ac felis donec et odio."
  },
  {
    image: {
      src: "https://placedog.net/300/302",
      alt: ""
    },
    url: "/",
    id: 3,
    title: "In cursus turpis",
    text:
      "Mi ipsum faucibus vitae aliquet. Venenatis a condimentum vitae sapien. Nulla pharetra diam sit amet nisl suscipit. Diam in arcu cursus euismod quis viverra nibh. Vitae semper quis lectus nulla at volutpat diam ut venenatis. Enim facilisis gravida neque convallis a cras semper auctor neque. Aliquam vestibulum morbi blandit cursus risus at."
  },
  {
    image: {
      src: "https://placedog.net/800/600",
      alt: ""
    },
    url: "/",
    id: 4,
    title: "Venenatis a condimentum",
    text:
      "Turpis massa tincidunt dui ut ornare lectus sit amet. Nunc sed velit dignissim sodales ut. Tempor commodo ullamcorper a lacus vestibulum sed. Feugiat nibh sed pulvinar proin gravida hendrerit lectus a. Volutpat blandit aliquam etiam erat velit scelerisque in dictum. Nisl tincidunt eget nullam non nisi est sit. Ac orci phasellus egestas tellus rutrum tellus pellentesque eu tincidunt. Enim sed faucibus turpis in eu mi bibendum neque egestas. Auctor augue mauris augue neque gravida. "
  },
  {
    image: {
      src: "https://placedog.net/600/801",
      alt: ""
    },
    url: "/",
    id: 5,
    title: "Id donec ultrices",
    text:
      "Adipiscing elit duis tristique sollicitudin nibh sit amet commodo nulla. Est ante in nibh mauris. Vitae congue eu consequat ac felis donec et odio. Pellentesque habitant morbi tristique senectus. Euismod lacinia at quis risus sed. Nam at lectus urna duis."
  },
  {
    image: {
      src: "https://placedog.net/300/300",
      alt: ""
    },
    url: "/",
    id: 6,
    title: "Adipiscing elit",
    text:
      "Nulla pharetra diam sit amet nisl suscipit. Diam in arcu cursus euismod quis viverra nibh. Vitae semper quis lectus nulla at volutpat diam ut venenatis. Enim facilisis gravida neque convallis a cras semper auctor neque. Aliquam vestibulum morbi blandit cursus risus at."
  }
];


const servicesData = [{
  contributors: {
    featureTitle: "Contributors and Collateralization",
    featureText: "The MIAMI ENGINE introduces an innovative and comprehensive framework for optimizing contributions in mineral assets, integrating elements of Asset Swapping, Collateralization, Escrow, and Tradeable Mineral Shares named 'Megajoules.' This platform fosters a dynamic ecosystem where contributors, asset managers, financial facilitators, Production Partners, and HANDAC as the share controlling body under Cornerstone engage in secure and transparent transactions",
    heading: "Contribution Dynamics",
    headingText: "Contributors actively participate by contributing various assets, including money, treasury bills, or equipment, to the MIAMI ENGINE system. This financial and resource commitment serves as the foundation for their engagement within MIAMI.",
    featureTitle_i: "Mineral-Backed Shares as Collateral",
    featureText_i: "In return for their contribution, contributors receive mineral-backed shares named 'Megajoules' as collateral. These shares represent tangible portions of assets held by HANDAC, acting as the share controlling body under Cornerstone, the registered asset management entity. Contributors hold these Megajoules as collateral during the contribution period.",
    featureTitle_ii: "Escrow for Initial Contribution:",
    featureText_ii: "Upon making an initial contribution, the funds or assets are placed in escrow. This provides contributors with a level of assurance that their contributions are safeguarded until the collateralization process is completed or until they receive mineral-backed shares.",
    featureTitle_iii: "Collateral Redemption",
    featureText_iii: "As contributors receive returns from Production Partners or other revenue streams, the collateralized mineral-backed shares (Megajoules) are gradually redeemed. This redemption process involves returning the Megajoules to HANDAC, the share controlling body under Cornerstone, marking the completion of the contribution cycle."
  },
  contributors: {
    featureTitle: "Contributors and Collateralization",
    featureText: "The MIAMI ENGINE introduces an innovative and comprehensive framework for optimizing contributions in mineral assets, integrating elements of Asset Swapping, Collateralization, Escrow, and Tradeable Mineral Shares named 'Megajoules.' This platform fosters a dynamic ecosystem where contributors, asset managers, financial facilitators, Production Partners, and HANDAC as the share controlling body under Cornerstone engage in secure and transparent transactions",
    heading: "Contribution Dynamics",
    headingText: "Contributors actively participate by contributing various assets, including money, treasury bills, or equipment, to the MIAMI ENGINE system. This financial and resource commitment serves as the foundation for their engagement within MIAMI.",
    featureTitle_i: "Mineral-Backed Shares as Collateral",
    featureText_i: "In return for their contribution, contributors receive mineral-backed shares named 'Megajoules' as collateral. These shares represent tangible portions of assets held by HANDAC, acting as the share controlling body under Cornerstone, the registered asset management entity. Contributors hold these Megajoules as collateral during the contribution period.",
    featureTitle_ii: "Escrow for Initial Contribution:",
    featureText_ii: "Upon making an initial contribution, the funds or assets are placed in escrow. This provides contributors with a level of assurance that their contributions are safeguarded until the collateralization process is completed or until they receive mineral-backed shares.",
    featureTitle_iii: "Collateral Redemption",
    featureText_iii: "As contributors receive returns from Production Partners or other revenue streams, the collateralized mineral-backed shares (Megajoules) are gradually redeemed. This redemption process involves returning the Megajoules to HANDAC, the share controlling body under Cornerstone, marking the completion of the contribution cycle."
  }

}
];

const TabsComponent = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  return (
    <div>
      <ul className="tabs">
        {[0, 1, 2, 3].map((id) => (
          <li
            key={id}
            className={id === activeTab ? 'active' : ''}
            data-id={id}
            onClick={() => handleTabClick(id)}
          >
            {tabTitles[id]}
          </li>
        ))}
      </ul>

      <div className="contents">
        {[0, 1, 2, 3].map((id) => (
          <div
            key={id}
            className={`boxxxx ${id === activeTab ? 'show' : 'hide'}`}
            data-content={id}
          >
            <img
              src={images[id]}
              alt=""
            />
            <div>
              <h3>Transform Megajoules into Real-World Products</h3>
              <p style={{ fontSize: "15px" }}>
                Use your Megajoules to swap with an array of products offered by Growth Seeking Assets.
                These assets, carefully evaluated and priced in Megajoules,
                allow you to diversify your portfolio with real-world goods,
                creating a dynamic and ever-growing investment landscape.
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const tabTitles = ['Tangible Assets', 'Dynamic Valuation', 'Secure', 'Transparent'];

const images = [
  'https://img.freepik.com/free-photo/man-showing-house-icon-couch_53876-56025.jpg?t=st=1710260559~exp=1710264159~hmac=d94f21462cf0066e9ab6ca0dbfe40c3a116c62a7a3e42d765691ea86b900d92b&w=996',
  'https://img.freepik.com/free-vector/financial-diversification-concept-with-investment-strategy-symbols-flat-vector-illustration_1284-78138.jpg?t=st=1710260689~exp=1710264289~hmac=c51d794f08767826658b9b7c671efab17419f42ee3f8fbdc59b4f7c76cca5585&w=1380',
  'https://img.freepik.com/free-vector/investing-concept-illustration_114360-2963.jpg?t=st=1710349311~exp=1710352911~hmac=64fe226a6fecc093e930c199ed6e2105dbd11c777a79c512b25531c5c7d17460&w=1060',
  'https://img.freepik.com/free-vector/business-success-growth-chart-arrow-concept_1017-20062.jpg?t=st=1710261668~exp=1710265268~hmac=b3acc5833c7a5f3512af346685640870008cb8fa50bb657a3b587ab695584d96&w=1060',
];

const slidez = [
  {
    imageUrl: 'https://firebasestorage.googleapis.com/v0/b/miami-auth-58a82.appspot.com/o/MIAMI%20PHOTOSE%2Ftransparent-quartz-crystal-precious-gemstone-illuminated-generated-by-ai.jpg?alt=media&token=7002b1d2-b0ae-4210-a91e-dcb578748076',
    title: 'We help you make modern interior',
    description: 'We will help you to make an elegant and luxurious interior designed by professional interior designers.',
    buttonText: 'Read more',
    buttonUrl: '#',
  },
  {
    imageUrl: 'https://firebasestorage.googleapis.com/v0/b/miami-auth-58a82.appspot.com/o/MIAMI%20PHOTOSE%2Fhousing.png?alt=media&token=c6a71e84-54ef-4f7e-ae84-727a53e60f7e',
    title: 'We help you make modern interior',
    description: 'We will help you to make an elegant and luxurious interior designed by professional interior designers.',
    buttonText: 'Read more',
    buttonUrl: '#',
  },
  {
    imageUrl: 'https://firebasestorage.googleapis.com/v0/b/miami-auth-58a82.appspot.com/o/MIAMI%20PHOTOSE%2Fcoal.jpeg?alt=media&token=9e70ad2c-6fd1-4b2e-b2f2-2ac89c498a1b',
    title: 'We help you make modern interior',
    description: 'We will help you to make an elegant and luxurious interior designed by professional interior designers.',
    buttonText: 'Read more',
    buttonUrl: '#',
  },
  {
    imageUrl: 'https://picsum.photos/id/167/1200/800',
    title: 'We help you make modern interior',
    description: 'We will help you to make an elegant and luxurious interior designed by professional interior designers.',
    buttonText: 'Read more',
    buttonUrl: '#',
  },
];



const HomeScreen = () => {
  const [pageLoad, setPageLoad] = useState(false)
  const [monitoring, setMonitoring] = useState(false)
  const [walletassets, setAssets] = useState(false)
  const [isHovered, setIsHovered] = useState(false);
  const [services, setService] = useState(true)
  const infoRef = useRef(null)
  const serviceRef = useRef(null)
  const [scrollPosition, setScrollPosition] = useState(0);
  const parallaxRef = useRef(null);


  const selectedService = (service) => {
    setClickedService(service)
    console.log("Services :"+ service)
  }
  // SERVICES AREA
  const Card = ({ cardClass, title, description, service }) => (
    <div className="ServiceCardd" onClick={() => selectedService(service)} >
      <div className={`ServiceCardd_img ${cardClass}`}>

      </div>
      <h3>{title}</h3>
      <div className="line"></div>
      <p>{description}</p>
    </div>
  );

  const [servic, setClickedService] = useState("assetRepo")

  const Viewport = () => {

    return (
      <div className="viewport">
        <div className="ServiceCardds">
          <Card
            service="assetRepo"
            cardClass="ServiceCardd1"
            title="Asset Repository"
            description="Asset Repository serves as a single, unified database where companies can register and manage all their assets. This includes detailed information about each asset, such as ownership history, current status, and any associated documentation. Whether you're dealing with properties, vehicles, intellectual property, or other valuable items, MIAMI ensures everything is organized and accessible."
          />
          <Card
            service="assetMoni"
            cardClass="ServiceCardd2"
            title="Asset Monitoring"
            description="Asset Monitoring provide comprehensive oversight and control over your assets, ensuring that they are performing as expected and that any irregularities are quickly identified and addressed. Whether you're managing a single asset or an extensive portfolio, MIAMI's system offers the tools you need to keep track of performance, costs, and overall asset health."
          />
          <Card
            cardClass="ServiceCardd3"
            title="Asset Marketing"
            description="Asset Marketing service is designed to help you effectively promote and sell your assets to a targeted audience. Whether you're looking to sell real estate, industrial equipment, or intellectual property, MIAMI provides the tools and resources needed to reach potential buyers and maximize the value of your assets"
          />
          <Card
            cardClass="ServiceCardd4"
            title="Asset Verification"
            description="Asset Verification  is designed to provide a robust and reliable process for confirming the authenticity, ownership, and legal status of assets. Whether you are a company, syndicate, or individual, MIAMI’s verification service ensures that all assets registered on the platform are legitimate and accurately documented, giving you peace of mind in your transactions and investments."
          />
          {/* bigger cards */}
          {/* <Card
        cardClass="ServiceCardd5"
        title="Asset Swap"
        description="Asset Swap service provides a powerful and innovative way for asset holders to exchange their assets with others, whether for strategic purposes, investment opportunities, or personal preferences. This service is designed to make the process of swapping assets easy, transparent, and secure, enabling users to diversify their portfolios and unlock new value from their holdings.This service allows users to exchange assets directly with one another in a seamless and straightforward manner. Whether you want to trade real estate for precious metals, exchange intellectual property for equity, or swap other valuable items, MIAMI facilitates these transactions with ease."
      /> */}
        </div>
      </div>

    )
  };

  // SERVICES AREA
  //***************************************************************************************************
  //            Swipper Component image slider discarded for now
  //===================================================================================================
  // Swiper.use([Navigation, Pagination]);

  // const TimelineSlider = ({ slides }) => {
  //   useEffect(() => {
  //     const mainSlider = new Swiper('.mySwiper2', {
  //       parallax: true,
  //       speed: 1200,
  //       effect: 'slide',
  //       direction: 'vertical',
  //       autoplay: true,
  //       navigation: {
  //         nextEl: '.upk-button-next',
  //         prevEl: '.upk-button-prev',
  //       },
  //       pagination: {
  //         el: '.swiper-pagination',
  //         clickable: true,
  //         renderBullet: function (index, className) {
  //           return `<span className="${className} swiper-pagination-bullet--svg-animation"><svg width="28" height="28" viewBox="0 0 28 28"><circle className="svg__circle" cx="14" cy="14" r="10" fill="none" stroke-width="2"></circle><circle className="svg__circle-inner" cx="14" cy="14" r="2" stroke-width="3"></circle></svg></span>`;
  //         },
  //       },
  //     });

  //     // Return a cleanup function to destroy the Swiper instance when the component unmounts
  //     return () => {
  //       mainSlider.destroy();
  //     };
  //   }, []);

  //   return (
  //     <div className="bdt-timeline-container">
  //       <div className="upk-salf-slider-wrapper">
  //         <div className="swiper-container mySwiper2">
  //           <div className="swiper-wrapper">
  //             {slides.map((slide, index) => (
  //               <div className="upk-salf-item swiper-slide" key={index}>
  //                 <div className="upk-salf-image-wrap">
  //                   <img className="upk-xanc-img" src={slide.imageUrl} alt={`Slide ${index + 1}`} />
  //                 </div>
  //                 <div className="upk-salf-content-wrap">
  //                   <h3 className="upk-salf-title" data-swiper-parallax-y="-150" data-swiper-parallax-duration="1200">
  //                     {slide.title}
  //                   </h3>
  //                   <div className="upk-salf-desc" data-swiper-parallax-y="-200" data-swiper-parallax-duration="1400">
  //                     {slide.description}
  //                   </div>
  //                   <div className="upk-salf-button" data-swiper-parallax-y="-300" data-swiper-parallax-duration="1500">
  //                     <a className="link link--arrowed" href={slide.buttonUrl}>{slide.buttonText}
  //                       <svg className="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 32 32">
  //                         <g fill="none" stroke="#ff215a" strokeWidth="1.5" strokeLinejoin="round" strokeMiterlimit="10">
  //                           <circle className="arrow-icon--circle" cx="16" cy="16" r="15.12"></circle>
  //                           <path className="arrow-icon--arrow" d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"></path>
  //                         </g>
  //                       </svg>
  //                     </a>
  //                   </div>
  //                 </div>
  //               </div>
  //             ))}
  //           </div>
  //         </div>
  //         <div className="upk-page-scroll">
  //           <a className="arrow-up">
  //             <div className="long-arrow-left"></div>
  //             <span className="arrow-slide"></span>
  //           </a>
  //         </div>
  //         <div className="upk-salf-nav-pag-wrap">
  //           <div className="upk-salf-navigation">
  //             <div className="upk-button-prev upk-n-p">
  //               <i className="link link--arrowed">
  //                 <svg className="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 32 32">
  //                   <g fill="none" stroke="#ff215a" strokeWidth="1.5" strokeLinejoin="round" strokeMiterlimit="10">
  //                     <circle className="arrow-icon--circle" cx="16" cy="16" r="15.12"></circle>
  //                     <path className="arrow-icon--arrow" d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"></path>
  //                   </g>
  //                 </svg>
  //               </i>
  //             </div>
  //             <div className="upk-button-next upk-n-p">
  //               <i className="link link--arrowed">
  //                 <svg className="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 32 32">
  //                   <g fill="none" stroke="#ff215a" strokeWidth="1.5" strokeLinejoin="round" strokeMiterlimit="10">
  //                     <circle className="arrow-icon--circle" cx="16" cy="16" r="15.12"></circle>
  //                     <path className="arrow-icon--arrow" d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"></path>
  //                   </g>
  //                 </svg>
  //               </i>
  //             </div>
  //           </div>
  //           <div className="upk-salf-pagi-wrap">
  //             <div className="swiper-pagination"></div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  //==================================================================================
  //                      End of Slider Component and ServiceCard Begins
  //==================================================================================
  // const ServiceCard = ({ imgSrc, title, description, buttonText, buttonLink }) => {
  //   return (
  //     <div className="servicecard">
  //       <div className="servicecontent">
  //         <div className="serviceback">
  //           <div className="serviceback-content">
  //             <img className="serviceimg" src={imgSrc} alt={title} style={{width: "200px"}}/>
  //             <strong style={{fontSize: "15px", color: "#FFC94A"}}>{title}</strong>
  //           </div>
  //         </div>
  //         <div className="servicefront">
  //           <div className="serviceimg">
  //             <div className="servicecircle"></div>
  //             <div className="servicecircle" id="serviceright"></div>
  //             <div className="servicecircle" id="servicebottom"></div>
  //           </div>
  //           <div className="servicefront-content">
  //             <small className="servicebadge">{title}</small>
  //             <div className="servicedescription">
  //               <div className="servicetitle">
  //                 <p className="servicetitle">{description}</p>
  //               </div>
  //             </div>
  //             <div className="servicebutton-container">
  //               <a href={buttonLink} className="servicecard-button">{buttonText}</a>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  //********************************************************************************************************



  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setPageLoad(true);

    setTimeout(() => {
      setPageLoad(false);
    }, 1000);

  }, []);

  return (
    <>
      {pageLoad ? (<div style={{ marginLeft: "auto", marginRight: "auto" }}><Loader /></div>) : (
        <>
          {/* <h1 style={{ backgroundColor: "black", color: "orange", textAlign: "center", fontSize: "25px", zIndex: "-10" }}>This system is under development</h1> */}
          <div className="gradieant__bg">
            <video className="background-video" autoPlay loop muted>
              <source src={africa} type='video/mp4' />
            </video>
            <div className="overlay"></div>
            <Navbar />
            <div ref={infoRef}></div>
            {services && (
              <>
                <Header
                  heading="MADE IN AFRICA MONETARY, MINING AND MENTORING INITIATIVE"
                  content="Welcome to the heart of our asset swapping ecosystem, powered by the Miami Megajoule Engine. At the forefront of innovation, the Miami Engine serves as the central nervous system of our platform, orchestrating seamless asset swapping operations and providing unparalleled insights into your contribution"
                  image="globe"
                />
              </>
            )}
          </div>

          {services && (
            <>
              <h1 style={{ color: "#c0d2e6", textAlign: "center", fontSize: "50px", padding: "20px" }}>WHAT WE DO</h1>
              <Viewport />
              {/* <div className="servicecard-container">
                <ServiceCard
                  imgSrc="https://www.svgrepo.com/show/489267/statistics.svg"
                  title="Growth Hub"
                  description="At GrowthHub, we're dedicated to fostering growth opportunities for growth seeking assets and innovative businesses. Our platform is designed to connect Growth Seekers with the necessary backing, resources, and trading opportunities to fuel growth."
                  buttonText="Start Growing"
                  buttonLink="https://www.google.com"
                />

                <ServiceCard
                  imgSrc="https://www.svgrepo.com/show/489260/safe-money.svg"
                  title="Growth Funders"
                  description="Discover a network of Backers eager to invest in promising growth seeking assets. Our platform connects you with backers aligned with your growth objectives. With Growth Funders, access the funding you need to propel your vision forward."
                  buttonText="Coming Soon"
                  buttonLink=""
                />

                <ServiceCard
                  imgSrc="https://www.svgrepo.com/show/489243/creativity-1.svg"
                  title="Trading Assets"
                  description="Trade your surplus assets with fellow Growth Seekers to optimize resources and drive mutual growth. MIAMI facilitates seamless asset exchange, empowering you to diversify, innovate, and thrive in a dynamic marketplace."
                  buttonText="Coming Soon"
                  buttonLink=""
                />

                <ServiceCard
                  imgSrc="https://www.svgrepo.com/show/489278/add-wallet.svg"
                  title="Rentals with Option to Purchase"
                  description="Explore flexible solutions for resource acquisition with our Rental with Option To Purchase feature. Whether you need equipment to boost production or seek access to premium goods and services, GrowthHub offers a range of rental options tailored to your needs."
                  buttonText="Coming Soon"
                  buttonLink=""
                />
              </div>
              <TimelineSlider slides={slidez} /> */}
            </>
          )}

          {servic === "assetRepo" ?
            <Features
              featuresD={AssetRepoData}
              heading="Asset Repository"
              contentP="Get Started"
            /> : null}

          {servic === "assetMoni" ?
            <Features
              featuresD={AssetMonitoringData}
              heading="Asset Monitoring"
              contentP="Get Started"
            />
            : null}

          {/* <Possibility
            subheading="Introducing Megajoule Token"
            heading="Megajoule"
            content="It is a dividend-bearing security token signifying a fractional share in an underlying asset, providing contributors with a stake in the returns generated by the asset. It's important to emphasize that owning these tokens does not convey direct ownership of the asset; instead, they serve as a financial instrument enabling contributors to receive a portion of the contribution payback."
            image="megajouleCoupon"
            linkUrl="/banking"
            linkText="Get Megajoules"
          /> */}

          {/* <div style={{ margin: "20px" }}>
            <TabsComponent />
          </div> */}
          {/* <Brand /> */}

          {/* <Carousel slides={slides} /> */}
          {/* {walletassets && (
            <>
              <div classNameName="cntainer slide-in-right" style={{ backgroundImage: "linear-gradient( 97.3deg,  rgba(25,50,70,0.81) 10.7%, rgba(250, 81, 9, 0.72) 39.5%, rgba(255,192,0,0.81) 69.7% )", width: "90%", marginLeft: "auto", marginRight: "auto", marginTop: '8px' }}>
                <WalletAssets showMenu={walletassets} close={closePopup} />
              </div>
            </>
          )} */}


          <WhatGPT3
            featureTitle="Continuous Improvement"
            modalActive={monitoring}
            featureText="At the heart of our platform lies a commitment to continuous improvement, ensuring that our technology evolves to meet the needs of a fast-changing world. Through ongoing research, innovation, and regular updates, we enhance platform performance, introduce new features, and maintain the highest standards of security and usability."
            heading="Untapped possibilities"
            headingText="Join us as we revolutionize the landscape of asset accumulation insight with the Miami Engine — where transparency meets innovation, and your asset accumulation success is our mission"
            featureTitle_i="User-Centric Design Updates"
            featureText_i="We listen to our users and prioritize enhancements that make a real difference. Through user feedback and industry research, we regularly refine our platform interface and workflows to provide a more intuitive, productive experience. Our goal is to ensure the platform not only meets but exceeds user expectations with each update."
            featureTitle_ii="Advanced Security Enhancements"
            featureText_ii="Security is never static, and neither are our defenses. We actively monitor industry trends, emerging threats, and security best practices, applying the latest encryption protocols, multi-factor authentication, and vulnerability patching. By staying proactive, we ensure your data remains protected, compliant, and secure across all platform services."
            featureTitle_iii="Scalability & Performance Optimization"
            featureText_iii="As your business grows, so does our platform. We continuously optimize our infrastructure to handle increased data loads, faster processing times, and larger user bases without compromising speed or reliability. By leveraging the latest in cloud computing and load-balancing technologies, we ensure that the platform scales seamlessly with your needs."
          />


          {/* <WhatGPT3
            featureTitle="Business Growth and Eliminating Poverty"
            featureText="According to a World Bank report, developing countries need to dramatically increase
            agricultural innovation and the use of technology by farmers, to eliminate poverty, meet the rising demand
            for food, and cope with the adverse effects of climate change"
            heading="How does MIAMI help you with your business?"
            headingText="Explore services"
            featureTitle_i="Providing Market"
            featureText_i="MIAMI provides a Market Platform for producers. By signing for one of  smart contracts you are able to join export programs such as the Red Sea Corridor,Morgen UK and China"
            featureTitle_ii="Software Systems that Coordinate production processes"
            featureText_ii="MIAMI acts as a co-ordinate system, our system is designed to help you streamline your production process
            and increase effeciency. The system collects real-time data to help you make informed decisions about your production process"
            featureTitle_iii="Providing Transport logistics"
            featureText_iii="Are your lookig for a reliable transport logistics provider for your business? As MIAMI Netzens you gain access to top quality logistics services
            for producers of all sizes."
          /> */}

          {/* <Possibility
            subheading="Mining and Minerals"
            heading="Garlpex Investiments Private Limited"
            content="Garlpex Investiments Limited is a licensed coal mining company established in 2012. The company is situated in Soonga in the North Western part of Matebeleland North Province in Hwange District Zimbabwe. Garlpex Mine has a coverage of 180 hectares of land. The mine has an estimated life span of 100 years. Over the past years the mine has been producing. Garlpex identified an opportunity in the COAL industry due to its high demands for COAL, COKE and its by products. The mine was established with the aim of maximizing profits in the COAL industry to compete favourably with leaders in the industry. We engage in open cast mining with an average production of 40 000 tonnes per month. Garlpex extracts COAL from the ground and processes it into COKING COAL, NUTS, PEAS and COBBLES, ready for sale. Our products found readily available at our local markets as well as international markets"
            image="garlpex"
            linkUrl="/commodity/garlpex"
            linkText="See Syndicate"
          /> */}

          {/* <Slider heading="Example Slider" slides={slideData} /> */}
          <CTA />

          {/* <Blog /> */}
          <Footer />
        </>
      )}

    </>
  );
};

export default HomeScreen;
